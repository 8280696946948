import React, { FunctionComponent } from 'react';
import BasicStructure from '../components/basic-structure/basic-structure';
import './die-stiftung.css';
import Kontaktdaten from '../components/kontaktdaten/kontaktdaten';
import skulpturBild from '../images/die-stiftung/skulptur-stiftung.jpg';
import { graphql, useStaticQuery } from 'gatsby';
import { DieStiftungDownloadQuery } from '../../graphql-types';
import Sidebar from '../components/sidebar/sidebar';
import ContentContainer from '../components/content-container/content-container';
import Footer from '../components/footer/footer';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Menubar from '../components/menu/menubar';
import Header from '../components/header/header';
import SideLanguageMenu from '../components/language-menu/side-language-menu';
import ScrollUp from '../components/scroll-up/scroll-up';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';

const DieStiftung: FunctionComponent = () => {
  const download = useStaticQuery<DieStiftungDownloadQuery>(
    graphql`
    query DieStiftungDownload {
      imageURL: allFile(filter: {extension: {eq: "png"}, name: {regex: "/^\\d+_die/"}}, sort: {fields: name}) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      pdfURL: allFile(filter: {extension: {eq: "pdf"}, name: {regex: "/^\\d+_die/"}}, sort: {fields: name}) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `,
  );

  console.log('die stiftung', download);
  const imageURLs = download.imageURL.edges.map((file) => {
    return file.node.publicURL ?? '';
  });
  const pdfUrls = download.pdfURL.edges.map((file) => {
    return file.node.publicURL ?? '';
  });

  return (
    <>
      <BasicStructure>
        <Header>
          <TitleMetaTags title="Die Stiftung" />
          <Menubar styleClass="main-menu" />
          <BurgerMenu>
            {/* <LanguageMenu imageUrls={imageURLs} pdfUrls={pdfUrls} /> */}
            {imageURLs && pdfUrls && (
              <SideLanguageMenu
                title={'Die Stiftung'}
                imageUrls={imageURLs}
                pdfUrls={pdfUrls}
              />
            )}
          </BurgerMenu>
        </Header>
        {imageURLs && pdfUrls && (
          <Sidebar>
            <div className="stiftung_sidebar">
              <SideLanguageMenu
                title={'Die Stiftung'}
                imageUrls={imageURLs}
                pdfUrls={pdfUrls}
              />
              <ScrollUp />
            </div>
          </Sidebar>
        )}
        <ContentContainer alignCenter={true}>
          <div className="die-stiftung__container">
            <div className="layout--margin-bottom">
              <blockquote>
                <p>
                  „Demokratie ist nichts anderes als der politische Aspekt des
                  Geistes ... <br /> Ich sehe heute, dass sich der deutsche
                  Bürger im Irrtum befand als er glaubte, der Mensch
                  <br />
                  könne kultiviert und unpolitisch sein“.
                </p>
                <cite>Thomas Mann 1939</cite>
              </blockquote>
            </div>

            <h1>Die Stiftung</h1>

            <div className="text-block content-text layout--margin-top">
              <p>
                Die gemeinnützige Internationale Stiftung zur Förderung von
                Kultur und Zivilisation wurde im Jahre 1995 von dem Unternehmer
                Erich Fischer (EBV) gegründet, der ihr ein Drittel seines
                Vermögens als Grundkapital schenkte und sie bis zum Jahre 2010
                als alleiniger Stiftungsvorstand prägte. Seit 2011 wird die
                Stiftung von Erich Fischer als Stifter und Stiftungsrat sowie
                den Stiftungsvorständen Jürgen Dorn und Eva Köhler geleitet.
              </p>
            </div>

            <div className="columns foundation-logo-story">
              <div className="col-2">
                <div className="goethe_sculpture-image__container">
                  <img
                    className="goethe_sculpture-image"
                    src={skulpturBild}
                    alt="Die Stiftung"
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="text-block-background foundation-logo-story__text">
                  <h3 className="foundation-logo-story__title">
                    Der Stein des guten Glücks
                  </h3>
                  <p>
                    Das Foto zeigt die Skulptur in Goethes Garten in Weimar, die
                    „Stein zum guten Glück“ genannt wird. Sie wurde von mir als
                    Vorlage für das Stiftungslogo entdeckt und fotografiert. Die
                    Skulptur verbindet die Kugel als Symbol für die
                    Unberechenbarkeit des Laufes des Glücks und den Kubus als
                    Symbol für den Versuch, die Welt rational zu bewältigen. In
                    dem Punkte, in dem sich beide berühren, verbindet sich das
                    Utopische mit dem Notwendigen, das es zu verwirklichen und
                    zu fördern gilt.
                  </p>
                  <p className="text-right">Eva Köhler, Stiftungsvorstand</p>
                </div>
              </div>
            </div>

            <div className="text-block content-text layout--margin-top">
              <p>Die wichtigsten Stiftungszwecke sind:</p>
              <p>Verbesserung der Lebensbedingungen älterer Menschen.</p>

              <p>
                Förderung von Kunst und Kultur insbesondere durch Aufführung
                vernachlässigter Kompositionen aller Epochen und die Aufführung
                von Bühnenwerken vernachlässigter Autoren, sowie die Förderung
                des Musikverständnisses, vor allem bei Kindern.
              </p>

              <p>
                Weiterentwicklung der Zivilisation insbesondere durch eine
                Humanisierung des Strafrechts und Strafvollzugs (Amnesty
                National) und die Verbesserung der freien Meinungsäußerung,
                Gleichstellung von Frau und Mann, sowie die allgemeine Förderung
                des freiheitlich demokratischen Rechtsstaats.
              </p>

              <p>
                In den Jahren von 1996 bis heute wurden neben vielen
                Einzelprojekten vor allem die unter „Projekte“ näher
                beschriebenen Stiftungszwecke verwirklicht.
              </p>
            </div>

            <Kontaktdaten isKontoverbindungVisible={true} />
          </div>
        </ContentContainer>
        <Footer />
      </BasicStructure>
    </>
  );
};

export default DieStiftung;
